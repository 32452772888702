
export var tableField = [
  { label: '操作人', code: 'czr', type: 'input', width: '100' },
  { label: '操作时间', code: 'czSj', type: 'input', width: '160' },
  { label: '操作', code: 'cz', type: 'input', width: '100' },
  { label: '操作描述', code: 'czMs', type: 'input', width: '300' }
]

export var tableData = [
  {
    czr: '王增芳',
    czSj: '2021-04-27 17:06:49',
    cz: '发布',
    czMs: '已完成'
  },
  {
    czr: '王增芳',
    czSj: '2021-04-27 17:06:36',
    cz: '评分汇总',
    czMs: '已完成'
  },
  {
    czr: '集团管理员',
    czSj: '2021-04-23 10:10:27',
    cz: '下发评分',
    czMs: '已完成'
  },
  {
    czr: '集团管理员',
    czSj: '2021-04-23 10:08:30',
    cz: '提交计算',
    czMs: '已完成'
  },
  {
    czr: '集团管理员',
    czSj: '2021-04-22 17:14:20',
    cz: '新建',
    czMs: '已完成'
  }
]
